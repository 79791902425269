body{
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./assets/lofi-cafe.gif');
  background-size: cover; 
  height: 100vh;
  padding:0;
  margin:0;
}

.main-container {
  color: aliceblue;
}

.main-screen-container {
  text-align: center;
  position: absolute;
  width: 600px;
  top: 20%;
  left: 50%;
  transform: translate(-50%);
}

.players-form input {
  background-color: transparent;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  color: aliceblue;
}

.players-form input:focus {
  background-color: transparent;
  caret-color: aliceblue;
  outline-color: aliceblue;
  color: aliceblue;
}

.players-form input::placeholder {
  color: aliceblue;
}

.players-form input:last-of-type {
  display: inline-block;
}

.start-button {
  width: 200px; 
}

.song-counter-container {
  float: left;
  margin: 10px;
}

.players-score-container {
  float: right;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.player-score-container {
  display: flex
}

.player-name {
  margin: 10px;
}

.player-score {
  margin: 10px;
}

.song-controls-container {
  text-align: center;
  position: absolute;
  width: 600px;
  top: 33%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.song-navigation-container {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  margin-top: 10px;
}

.new-round-container {
  margin-top: 10px;
}

.App-logo {
  height: 25vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
